import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Hjelp" />
    <div className="longtext">
      <h2>Hjelp for Mente</h2>
      <ul>
        <li>
          <Link to="/help/uvp">Hva skiller Mente fra andre todo-apper?</Link>
        </li>
        <li>
          <Link to="/help/platforms">Hvilke plattformer støttes?</Link>
        </li>
        <li>
          <Link to="/help/lists">Lister</Link>
        </li>
        <li>
          <Link to="/help/siri">Siri og snarveier</Link>
        </li>
        <li>
          <Link to="/help/payment">Abonnement og betaling</Link>
        </li>
        <li>
          <Link to="/help/keyboard-shortcuts">Tastatursnarveier</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
